import numbro from "numbro";

const customLocale: numbro.NumbroLanguage = {
  languageTag: "ru-RU",
  delimiters: {
    thousands: " ",
    decimal: ".",
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function () {
    return "";
  },
  currency: {
    symbol: "$",
    position: "prefix",
    code: "USD",
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: "currency",
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: "currency",
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
};

numbro.registerLanguage(customLocale);
numbro.setLanguage("ru-RU");
