import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlayersToChoose } from "./actions";
// import { selectNeedToShowChooseRoomPage } from "./selectors";
import { selectPortableMode } from "../App/selectors";

import FullScreenStats from "./containers/FullScreenStats/FullScreenStats";
import PortableLayout from "./containers/PortableLayout/PortableLayout";

import { useSearchParams } from "react-router-dom";
import { showPage } from "../App/actions";
import { useAuth } from "../../hooks/useAuth";

const StatPage = React.memo(() => {
  const dispatch = useDispatch();
  useAuth();

  // const needToShowChooseRoom = useSelector(selectNeedToShowChooseRoomPage);
  const portableMode = useSelector(selectPortableMode);

  const [searchParams] = useSearchParams();
  const playername = searchParams.get("playername");

  React.useEffect(() => {
    dispatch(showPage("StatPage"));
    if (playername) {
      dispatch(getPlayersToChoose(playername));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (portableMode) {
    return <PortableLayout />;
  }

  return <FullScreenStats />;

  // if (needToShowChooseRoom) {
  //   return <FullScreenChooseRoomPage />;
  // } else {
  //   return <FullScreenStats />;
  // }
});

export default StatPage;
