import React from "react";
import "./Text.css";

import classNames from "classnames";

type Props = React.PropsWithChildren<{
  className?: string;
  type?: "default" | "primary" | "secondary" | "tertiary" | "success" | "error";
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  bold?: boolean;
}>;

const Text = React.memo(
  ({ children, className, type = "default", size = 2, bold }: Props) => {
    return (
      <span
        className={classNames(className, "Text", `Text--size-${size}`, {
          "Text--default": type === "default",
          "Text--secondary": type === "secondary",
          "Text--tertiary": type === "tertiary",
          "Text--success": type === "success",
          "Text--error": type === "error",
          "Text--primary": type === "primary",
          "Text--bold": bold,
        })}
      >
        {children}
      </span>
    );
  }
);

export default Text;
