import { call, fork, put, take } from "redux-saga/effects";

import {
  logoutSucces,
  initialLoadProcessed,
  logout,
  showVerifyAfterSignIn,
} from "./actions";

import apiService from "../../api/apiServices/apiService";
import { history } from "../../main";

function* watchLogout() {
  while (true) {
    yield take(logout.type);

    const { success } = yield call([apiService, apiService.logout]);

    if (success) {
      yield put(logoutSucces());
      history.push("/");
    }
  }
}

export default function* appSaga() {
  const { success } = yield call([apiService, apiService.user]);
  if (success) {
    yield put(showVerifyAfterSignIn(success.payload));
    history.push(`/statistics`);
  }

  yield put(initialLoadProcessed(true));

  yield fork(watchLogout);
}
