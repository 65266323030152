import PlayerDto from "../../dto/PlayerDto";
import AutocompletePlayerModel from "../../viewModel/AutocompletePlayerModel";
import PlayerToChooseRoomModel from "../../viewModel/PlayersToChooseRoomModel";

export const pokerSitePostfix: { [key in 1]: string } = {
  1: "PS",
};

export function prepareLoadedPlayers(
  players: Array<PlayerDto>
): Array<AutocompletePlayerModel> {
  return players.map(x => ({
    id: x.id,
    playername: x.playername,
    pokersiteId: x.pokersite_id,
    label: `${x.playername} (${pokerSitePostfix[x.pokersite_id]})`,
  }));
}

export function prepareLoadedPlayersToChoose(
  players: Array<PlayerDto>
): Array<PlayerToChooseRoomModel> {
  return players.map(x => ({
    playername: x.playername,
    pokersiteId: x.pokersite_id,
  }));
}
