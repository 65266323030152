import { configureStore } from "@reduxjs/toolkit";

import createSagaMiddleware, { Saga } from "redux-saga";

import { configureApi } from "./api";

import { globalSlice } from "./containers/App/reducer";
import globalSaga from "./containers/App/sagas";

import homeSaga from "./containers/HomePage/sagas";
import registerSaga from "./containers/RegisterPage/sagas";
import { statReducer } from "./containers/StatPage/reducer";
import statSaga from "./containers/StatPage/sagas";
import resetSaga from "./containers/ResetPass/sagas";
import confirmSaga from "./containers/ConfirmPage/sagas";
import userProfileSaga from "./containers/UserProfilePage/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    [globalSlice.name]: globalSlice.reducer,
    [statReducer.name]: statReducer.reducer,
  },
  devTools: true,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: false,
    }).prepend(sagaMiddleware),
});

configureApi(sagaMiddleware);
sagaMiddleware.run(globalSaga as Saga);
sagaMiddleware.run(homeSaga);
sagaMiddleware.run(registerSaga);
sagaMiddleware.run(resetSaga);
sagaMiddleware.run(confirmSaga);
sagaMiddleware.run(statSaga);
sagaMiddleware.run(userProfileSaga);

export type RootState = ReturnType<typeof store.getState>;
export default store;
