import { createAction } from "@reduxjs/toolkit";

import PagesType from "../../types/PagesType";

import User from "../../types/User";
import LoginDto from "../../dto/LoginDto";

export const initialLoadProcessed = createAction<boolean>(
  "app/App/INITIAL_LOAD"
);
export const setPortableMode = createAction<boolean>(
  "app/App/SET_PORTABLE_MODE"
);

export const showApiCallSpinner = createAction("app/App/SHOW_API_CALL_SPINNER");
export const hideApiCallSpinner = createAction("app/App/HIDE_API_CALL_SPINNER");
export const logout = createAction("app/App/LOGOUT");
export const logoutSucces = createAction("app/App/LOGOUT_SUCCES");
export const cookieSignIn = createAction("app/App/COOKIE_SIGN_IN");
export const showPage = createAction<PagesType>("app/App/SHOW_PAGE");
export const signIn = createAction<{ email: string; password: string }>(
  "app/App/SIGN_IN"
);
export const setVerifiedUser = createAction<LoginDto>(
  "app/App/SET_VERIFIED_USER"
);
export const showVerifyAfterSignIn = createAction<User>(
  "app/App/SHOW_VERIFY_AFTER_SIGN_IN"
);
export const refreshUsersTokens = createAction<{
  accessToken: string;
  refreshToken: string;
}>("app/App/REFRESH_ACCESS_TOKENS_SUCCESS");
