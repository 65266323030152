import React from "react";
import "./Tables.css";
import Text from "../Text/Text";
import classNames from "classnames";

type Props = {
  columns: Array<{
    title: string;
    dataIndex: string;
    render?: (x: any) => React.ReactNode;
  }>;
  data: Array<any>;
  spaceIndexes?: Array<number>;
};

const Table = React.memo(({ columns, data, spaceIndexes }: Props) => {
  return (
    <table className="Table">
      <thead>
        <tr>
          {columns.map((x, index) => (
            <th
              className="Table--th"
              key={index}
            >
              <Text>{x.title}</Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="Table--tbody">
        {data.map((x, index) => (
          <tr
            key={index}
            className={classNames({
              "Table--tr-space": spaceIndexes && spaceIndexes.includes(index),
            })}
          >
            {columns.map(c => (
              <td
                className="Table--td"
                key={c.dataIndex}
              >
                {c.render ? c.render(x) : <Text>{x[c.dataIndex]}</Text>}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default Table;
