import * as React from "react";
import "./PortableChart.css";
import { useSelector } from "react-redux";
import { selectGraphResults, selectGraphSpinner } from "../../selectors";
import Flex from "../../../../components/Flex/Flex";
import Loader from "../../../../components/Loader/Loader";
import { Line, LineChart, ReferenceLine, ResponsiveContainer } from "recharts";

const PortableChart = React.memo(() => {
  const graphResults = useSelector(selectGraphResults);
  const loading = useSelector(selectGraphSpinner);

  if (loading) {
    return (
      <Flex
        className="PortableChart__Loading"
        justify="center"
        align="center"
      >
        <Loader />
      </Flex>
    );
  }

  if (!graphResults) return null;

  const { result } = graphResults;

  const data = result.reduce<Array<{ result: number }>>(
    (acc, res) => {
      acc.push({
        result: +(acc[acc.length - 1].result + res / 100).toFixed(1),
      });
      return acc;
    },
    [{ result: 0 }]
  );

  return (
    <div className="PortableChart">
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <LineChart data={data}>
          <ReferenceLine
            y={0}
            stroke="#484D57"
          />
          <Line
            type="monotone"
            dataKey="result"
            stroke="var(--color-primary)"
            dot={false}
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});

export default PortableChart;
