import React from "react";
import classNames from "classnames";
import "./Button.css";

type ButtonProps = React.PropsWithChildren<{
  className?: string;
  type?: "default" | "primary";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Button = React.memo(
  ({ className, children, onClick, type = "default" }: ButtonProps) => {
    return (
      <button
        onClick={onClick}
        className={classNames(className, "Button", {
          "Button--default": type === "default",
          "Button--primary": type === "primary",
        })}
      >
        {children}
      </button>
    );
  }
);

export default Button;
