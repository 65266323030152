import * as React from "react";
import "./FullScreenStats.css";
import { useSelector } from "react-redux";

import { selectPlayerToShowStats } from "../../selectors";

import BasicStats from "../BasicStats/BasicStats";
import PreflopStats from "../PreflopStats/PreflopStats";
import Flex from "../../../../components/Flex/Flex";
import PostflopStats from "../PostflopStats/PostflopStats";
import SummaryStats from "../SummaryStats/SummaryStats";
import HistoryStats from "../HistoryStats/HistoryStats";
import Chart from "../Chart/Chart";
import StatsHeader from "../StatsHeader/StatsHeader";

const FullScreenStats = React.memo(() => {
  const playerToShowStats = useSelector(selectPlayerToShowStats);

  if (!playerToShowStats) {
    return null;
  }

  return (
    <Flex
      className="FullScreenStats"
      vertical
      gap={16}
    >
      <StatsHeader />
      <Flex
        gap={8}
        wrap
        justify="center"
      >
        <Flex
          className="FullScreenStats--block"
          vertical
          gap={8}
        >
          <BasicStats />
          <PreflopStats />
          <PostflopStats />
          <SummaryStats />
        </Flex>
        <Flex
          className="FullScreenStats--block"
          vertical
          gap={8}
        >
          <Chart />
          <HistoryStats />
        </Flex>
      </Flex>
    </Flex>
  );
});

export default FullScreenStats;
