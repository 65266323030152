import { createAction } from "@reduxjs/toolkit";
import AutocompletePlayerModel from "../../viewModel/AutocompletePlayerModel";
import PlayerStatsDto from "../../dto/PlayerStatsDto";
import PlayerGraphResultsDto from "../../dto/PlayerGraphResultsDto";
import PlayerToChooseRoomModel from "../../viewModel/PlayersToChooseRoomModel";

export const findStats = createAction<{
  playername: string;
  pokersiteId: 1;
}>("App/StatPage/FIND_STATS");
export const changePlayernameToFind = createAction<string>(
  "App/StatPage/CHANGE_NICKNAME_TO_FIND"
);
export const changeLimitFilter = createAction<number | undefined>(
  "App/StatPage/CHANGE_LIMIT_FILTER"
);
export const changeTableSizeFilter = createAction<number | undefined>(
  "App/StatPage/CHANGE_TABLE_SIZE_FILTER"
);
export const changeDateFilter = createAction<{
  value?: number;
  customValue?: { fromDate: string; toDate: string };
}>("App/StatPage/CHANGE_DATE_FILTER");
export const setLoadedPlayers = createAction<Array<AutocompletePlayerModel>>(
  "App/StatPage/SET_LOADED_AUTOCOMPLETE_PLAYERS"
);
export const getPlayersToChoose = createAction<string>(
  "App/StatPage/GET_PLAYERS_TO_CHOOSE"
);
export const setLoadedPlayersToChoose = createAction<
  Array<PlayerToChooseRoomModel>
>("App/StatPage/SET_LOADED_PLAYERS_TO_CHOOSE");
export const setLoadedBaseStats = createAction<PlayerStatsDto>(
  "App/StatPage/SET_LOADED_BASE_STATS"
);
export const setLoadedGraphStats = createAction<PlayerGraphResultsDto>(
  "App/StatPage/SET_LOADED_GRAPH_STATS"
);
export const showLoadingGraphSpinner = createAction(
  "App/StatPage/SHOW_LOADING_GRAPH_SPINNER"
);
export const showGraphError = createAction("App/StatPage/SHOW_GRAPH_ERROR");
export const dropFilters = createAction("App/StatPage/DROP_FILTERS");
