import React from "react";
import "./Suggestions.css";
import Text from "../Text/Text";

export type Suggestion = {
  id: number;
  label: string;
};

type SuggestionsProps = {
  suggestions: Array<Suggestion>;
  onClick: (suggestion: Suggestion) => void;
};

const Suggestions = React.memo(({ suggestions, onClick }: SuggestionsProps) => {
  if (suggestions.length === 0) {
    return null;
  }

  return (
    <div className="Suggestions">
      {suggestions.map(x => (
        <div
          className="Suggestions__Suggestion"
          key={x.id}
          onClick={() => onClick(x)}
        >
          <Text size={3}>{x.label}</Text>
        </div>
      ))}
    </div>
  );
});

export default Suggestions;
