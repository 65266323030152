import React from "react";
import "./HistoryStats.css";
import Card from "../../../../components/Card/Card";
import Table from "../../../../components/Table/Table";
import { useSelector } from "react-redux";
import { selectMonthStats } from "../../selectors";
import { ShortStatsDto } from "../../../../dto/PlayerStatsDto";
import Text from "../../../../components/Text/Text";
import formatHands from "../../../../utils/formatHands";
import formatWinnings from "../../../../utils/formatWinnings";

const HistoryStats = React.memo(() => {
  const monthStats = useSelector(selectMonthStats);

  return (
    <Card title="History">
      <Table
        columns={[
          {
            title: "",
            dataIndex: "month",
          },
          {
            title: "Hands",
            dataIndex: "hands",
            render: (x: ShortStatsDto) => (
              <Text>{formatHands(x.hands, 1)}</Text>
            ),
          },
          {
            title: "Winnings",
            dataIndex: "winnings",
            render: (x: ShortStatsDto) => (
              <Text type={x.winnings > 0 ? "success" : "error"}>
                {formatWinnings(x.winnings, 0, false)}
              </Text>
            ),
          },
          { title: "bb/100", dataIndex: "bb100" },
        ]}
        data={monthStats.filter((_, i) => i < 12)}
      />
    </Card>
  );
});

export default HistoryStats;
