import httpService from "./http-service";

export default function chooseRequest(requestType: "HTTP") {
  switch (requestType) {
    case "HTTP":
      return httpService;
    default:
      return httpService;
  }
}
