import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAutocomletePlayers,
  selectNicknameToFind,
} from "../containers/StatPage/selectors";
import {
  changePlayernameToFind,
  findStats,
  dropFilters,
  setLoadedPlayers,
} from "../containers/StatPage/actions";
import { Suggestion } from "../components/Suggestions/Suggestions";

export default function useSearchPlayers() {
  const dispatch = useDispatch();

  const nicknameToFind = useSelector(selectNicknameToFind);
  const autocompletePlayers = useSelector(selectAutocomletePlayers);

  const changeNicknameToFind = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changePlayernameToFind(e.target.value));
    },
    [dispatch]
  );

  const selectPlayer = React.useCallback(
    (suggestion: Suggestion) => {
      const player = autocompletePlayers.find(x => x.id === suggestion.id);

      if (player) {
        dispatch(dropFilters());
        dispatch(
          findStats({
            playername: player.playername,
            pokersiteId: player.pokersiteId,
          })
        );
      }
    },
    [dispatch, autocompletePlayers]
  );

  const clearAutocompletePlayers = React.useCallback(() => {
    dispatch(setLoadedPlayers([]));
  }, [dispatch]);

  const suggestions = React.useMemo(
    () =>
      autocompletePlayers.map(x => ({
        id: x.id,
        label: x.label,
      })),
    [autocompletePlayers]
  );

  return {
    nicknameToFind,
    changeNicknameToFind,
    selectPlayer,
    clearAutocompletePlayers,
    suggestions,
  };
}
