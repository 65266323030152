import numbro from "numbro";

export default function formatHands(num: number, mantissa = 0) {
  const format: numbro.Format = {
    mantissa: mantissa,
    trimMantissa: true,
  };

  if (num >= 1e3) {
    let forceAvg: numbro.Format["forceAverage"];
    switch (true) {
      case num < 1e6:
        forceAvg = "thousand";
        break;
      case num < 1e9:
        forceAvg = "million";
        break;
      case num < 1e12:
        forceAvg = "billion";
        break;
      default:
        forceAvg = "trillion";
    }
    format.forceAverage = forceAvg;
    format.average = true;
  }

  return numbro(num).format(format);
}
