import * as React from "react";
import "./PortableStats.css";
import { useSelector } from "react-redux";
import { selectGeneralStats, selectPlayerToShowStats } from "../../selectors";
import PortableBasicStats from "../PortableBasicStats/PortableBasicStats";
import Flex from "../../../../components/Flex/Flex";
import Text from "../../../../components/Text/Text";
import { PreflopStatsList } from "../PreflopStats/PreflopStats";
import { PostflopStatsList } from "../PostflopStats/PostflopStats";
import PortableChart from "../PortableChart/PortableChart";

const PortableStats = React.memo(() => {
  const playerToShowStats = useSelector(selectPlayerToShowStats);
  const generalStats = useSelector(selectGeneralStats);

  if (!playerToShowStats) {
    return null;
  }

  return (
    <div>
      <PortableBasicStats />
      <Flex
        vertical
        gap={16}
        className="PortableStats__MainStats"
      >
        <Flex
          showDivider
          justify="space-between"
        >
          <Flex
            gap={8}
            className="PortableStats__content-group"
          >
            <Flex
              vertical
              align="flex-end"
            >
              <Flex
                flex
                align="center"
              >
                <Text
                  size={3}
                  bold
                  type="primary"
                >
                  VPIP
                </Text>
              </Flex>
              <Flex
                flex
                align="center"
              >
                <Text
                  size={3}
                  bold
                  type="primary"
                >
                  PFR
                </Text>
              </Flex>
            </Flex>
            <Flex
              vertical
              align="center"
            >
              <Text
                size={6}
                bold
              >
                {generalStats.vpip}
              </Text>
              <Text
                size={6}
                bold
              >
                {generalStats.pfr}
              </Text>
            </Flex>
          </Flex>
          <PreflopStatsList
            names={["WTSD", "WWSF", "W$SD"]}
            values={[
              generalStats.sawshowdown,
              generalStats.wonwhensawflop,
              generalStats.wonshowdown,
            ]}
          />
          <PreflopStatsList
            names={["3bet", "F4bet", "5bet"]}
            values={[
              generalStats.threebet,
              generalStats.foldedtofourbetpreflop,
              generalStats.raisedfourbetpreflop,
            ]}
          />
          <PreflopStatsList
            names={["F3bet", "4bet"]}
            values={[
              generalStats.foldedtothreebetpreflop,
              generalStats.raisedthreebetpreflop,
            ]}
          />
        </Flex>
        <Flex
          gap={16}
          align="center"
        >
          <Flex>
            <Flex
              vertical
              gap={8}
              justify="flex-end"
            >
              <Text
                type="primary"
                bold
              >
                FLP
              </Text>
              <Text
                type="primary"
                bold
              >
                TRN
              </Text>
              <Text
                type="primary"
                bold
              >
                RVR
              </Text>
            </Flex>
            <PostflopStatsList
              name="Bet"
              values={[
                generalStats.flopbet,
                generalStats.turnbet,
                generalStats.riverbet,
              ]}
            />
            <PostflopStatsList
              name="FvBet"
              values={[
                generalStats.flopfold,
                generalStats.turnfold,
                generalStats.riverfold,
              ]}
            />
            <PostflopStatsList
              name="RBet"
              values={[
                generalStats.flopraise,
                generalStats.turnraise,
                generalStats.riverraise,
              ]}
            />
            <PostflopStatsList
              name="Agf%"
              values={[
                generalStats.flopagrfactor,
                generalStats.turnagrfactor,
                generalStats.riveragrfactor,
              ]}
            />
          </Flex>
          <PortableChart />
        </Flex>
      </Flex>
    </div>
  );
});

export default PortableStats;
