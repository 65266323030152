import * as React from "react";
import "./ResetPass.css";
import { useDispatch } from "react-redux";
import { resetPassword } from "./actions";
import Input from "../../components/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import Logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import FormItem from "../../components/FormItem/FormItem";

type FormValues = {
  email: string;
};

const ResetPass = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({});

  const onSubmit = handleSubmit(({ email }) => {
    dispatch(resetPassword(email));
  });

  const handleRedirectHome = () => {
    navigate("/");
  };

  return (
    <div className="ResetPass">
      <img
        className="ResetPass__Logo"
        src={Logo}
        onClick={handleRedirectHome}
      />
      <Text
        className="ResetPass__Title"
        size={5}
        bold
      >
        Password recovery
      </Text>
      <Text
        className="ResetPass__Description"
        size={2}
      >
        Enter your email address and we will send you a link to restore your
        password
      </Text>
      <form
        className="ResetPass__Form"
        onSubmit={onSubmit}
      >
        <FormItem error={errors.email?.message}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            render={({ field }) => (
              <Input
                placeholder="Email"
                autoComplete="off"
                aria-invalid={errors.email?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <Button
            className="ResetPass__Action"
            type="primary"
          >
            Reset
          </Button>
        </FormItem>
      </form>
    </div>
  );
});

export default ResetPass;
