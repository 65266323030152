import { call, put, race, take } from "redux-saga/effects";
import { ApiActions, requestLifecycleFromType } from "./actions";
import { ApiRequest } from "../types";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  SERVER_ADDRESSES,
  SERVER_APPLICATION_NAMES,
} from "../../config/serverAddresses";

export class ActionService {
  public serverAddress: string;
  public requestType: "HTTP";
  private applicationName: string;

  constructor(
    serverAddress: string,
    requestType: "HTTP",
    applicationName: string
  ) {
    this.serverAddress = serverAddress;
    this.requestType = requestType;
    this.applicationName = applicationName;
  }

  public *Get(
    controller: string,
    id?: number,
    query?: object,
    showApiCallSpinner = true
  ) {
    const payload: ApiRequest = {
      query,
      showApiCallSpinner,
    };

    this.SetIdParamToPayload(payload, id);

    const action = ApiActions.apiAction(
      this.applicationName,
      controller,
      "GET",
      this.serverAddress,
      payload,
      this.requestType
    );

    const result: ReturnType<typeof this.MakeRequest> = yield call(
      this.MakeRequest,
      action
    );
    return result;
  }

  public *Post(
    controller: string,
    body?: object,
    id?: number,
    query?: object,
    showApiCallSpinner = true
  ) {
    const payload: ApiRequest = {
      body,
      query,
      showApiCallSpinner,
    };

    this.SetIdParamToPayload(payload, id);
    const action = ApiActions.apiAction(
      this.applicationName,
      controller,
      "POST",
      this.serverAddress,
      payload,
      this.requestType
    );

    const result: ReturnType<typeof this.MakeRequest> = yield call(
      this.MakeRequest,
      action
    );
    return result;
  }

  public *Patch(
    controller: string,
    id: number,
    body?: object,
    query?: object,
    showApiCallSpinner = true
  ) {
    const payload: ApiRequest = {
      body,
      query,
      showApiCallSpinner,
    };

    this.SetIdParamToPayload(payload, id);
    const action = ApiActions.apiAction(
      this.applicationName,
      controller,
      "PATCH",
      this.serverAddress,
      payload,
      this.requestType
    );

    const result: ReturnType<typeof this.MakeRequest> = yield call(
      this.MakeRequest,
      action
    );
    return result;
  }

  public *Put(
    controller: string,
    id: number,
    body: object,
    query?: object,
    showApiCallSpinner = true
  ) {
    const payload: ApiRequest = {
      body,
      query,
      showApiCallSpinner,
    };

    this.SetIdParamToPayload(payload, id);

    const action = ApiActions.apiAction(
      this.applicationName,
      controller,
      "PUT",
      this.serverAddress,
      payload,
      this.requestType
    );

    const result: ReturnType<typeof this.MakeRequest> = yield call(
      this.MakeRequest,
      action
    );
    return result;
  }

  public *Delete(
    controller: string,
    id: number,
    query?: object,
    showApiCallSpinner = true
  ) {
    const payload: ApiRequest = {
      query,
      showApiCallSpinner,
    };

    this.SetIdParamToPayload(payload, id);

    const action = ApiActions.apiAction(
      this.applicationName,
      controller,
      "DELETE",
      this.serverAddress,
      payload,
      this.requestType
    );

    const result: ReturnType<typeof this.MakeRequest> = yield call(
      this.MakeRequest,
      action
    );
    return result;
  }

  private SetIdParamToPayload(payload: ApiRequest, id?: number): void {
    if (id) {
      const paramsMap = new Map<string, unknown>();
      paramsMap.set("id", id);
      payload.params = paramsMap;
    }
  }

  private *MakeRequest(requestAction: ReturnType<typeof ApiActions.apiAction>) {
    const lifecycle = requestLifecycleFromType(requestAction.type);
    yield put(requestAction);
    const {
      success,
      reject,
    }: { success: PayloadAction<unknown>; reject: PayloadAction<unknown> } =
      yield race({
        success: take(lifecycle.RESOLVED),
        reject: take(lifecycle.REJECTED),
      });

    return {
      success,
      reject,
    };
  }
}

export const oldApiService = new ActionService(
  SERVER_ADDRESSES.API,
  "HTTP",
  SERVER_APPLICATION_NAMES.API
);
