import { fork, take, put, select, call } from "redux-saga/effects";
import * as qs from "qs";
import {
  setLoadedPlayers,
  findStats,
  setLoadedPlayersToChoose,
  setLoadedBaseStats,
  setLoadedGraphStats,
  showLoadingGraphSpinner,
  showGraphError,
  changePlayernameToFind,
  getPlayersToChoose,
  changeDateFilter,
  changeLimitFilter,
  changeTableSizeFilter,
} from "./actions";

import {
  selectLimitFilterValue,
  selectDateFilterValue,
  selectPlayerToShowStats,
  selectTableSizeFilterValue,
  selectCustomDateFilterValue,
} from "./selectors";
import { prepareLoadedPlayers, prepareLoadedPlayersToChoose } from "./utils";
import PlayerDto from "../../dto/PlayerDto";
import PlayerStatsDto from "../../dto/PlayerStatsDto";
import { history } from "../../main";
import PlayerGraphResultsDto from "../../dto/PlayerGraphResultsDto";
import apiService from "../../api/apiServices/apiService";
import { DateOptions, LimitOptions, TableSizeOptions } from "./constants";
import { toast } from "react-toastify";

function* findGraphResultsWorker(
  playername: string,
  pokersiteId: number,
  filters: ReturnType<typeof generateFilters>
) {
  yield put(showLoadingGraphSpinner());

  const { success, reject } = yield call(
    [apiService, apiService.getPlayerGraph],
    playername,
    pokersiteId,
    filters
  );

  if (success) {
    yield put(setLoadedGraphStats(success.payload as PlayerGraphResultsDto));
  }

  if (reject) {
    yield put(showGraphError());
  }
}
function* findStatsWorker(
  playername: string,
  pokersiteId: number,
  filters: ReturnType<typeof generateFilters>
) {
  const { success, reject } = yield call(
    [apiService, apiService.getPlayerStats],
    playername,
    pokersiteId,
    filters
  );

  if (success) {
    const payload = success.payload as PlayerStatsDto;
    yield put(setLoadedBaseStats(payload));

    const querystring = qs.stringify({ playername });
    history.push(`/statistics?${querystring}`);
  }

  if (reject) {
    toast.error(reject.payload.message);
  }
}

function* watchFindStat() {
  while (true) {
    const { payload }: ReturnType<typeof findStats> = yield take(findStats);

    const { playername, pokersiteId } = payload;

    const limitFilter: ReturnType<typeof selectLimitFilterValue> = yield select(
      selectLimitFilterValue
    );
    const tableSizeFilter: ReturnType<typeof selectTableSizeFilterValue> =
      yield select(selectTableSizeFilterValue);

    const dateFilter: ReturnType<typeof selectDateFilterValue> = yield select(
      selectDateFilterValue
    );

    const customDateFilter: ReturnType<typeof selectCustomDateFilterValue> =
      yield select(selectCustomDateFilterValue);

    const filters = generateFilters(
      tableSizeFilter,
      limitFilter,
      dateFilter,
      customDateFilter
    );

    yield call(findStatsWorker, playername, pokersiteId, filters);
    yield call(findGraphResultsWorker, playername, pokersiteId, filters);
  }
}

function* watchChangePlayernameToFind() {
  while (true) {
    const { payload }: ReturnType<typeof changePlayernameToFind> = yield take(
      changePlayernameToFind.type
    );
    const playername = payload;

    const { success, reject } = yield call(
      [apiService, apiService.getPlayers],
      playername
    );

    if (success) {
      const players = prepareLoadedPlayers(success.payload as Array<PlayerDto>);
      yield put(setLoadedPlayers(players));
    }

    if (reject) {
      yield put(setLoadedPlayers([]));
    }
  }
}

function* watchGetPlayersToChoose() {
  while (true) {
    const { payload }: ReturnType<typeof getPlayersToChoose> = yield take(
      getPlayersToChoose.type
    );

    const { success, reject } = yield call(
      [apiService, apiService.getPlayerToChoose],
      payload
    );

    if (success) {
      const players = prepareLoadedPlayersToChoose(
        success.payload as Array<PlayerDto>
      );

      if (players.length === 1) {
        yield put(
          findStats({
            playername: players[0].playername,
            pokersiteId: players[0].pokersiteId,
          })
        );
      } else {
        yield put(setLoadedPlayersToChoose(players));
      }
    }

    if (reject) {
      toast.error(reject.payload.message);
    }
  }
}

function* watchChangeDateToFind() {
  while (true) {
    yield take(changeDateFilter.type);

    const {
      playername,
      pokersiteId,
    }: ReturnType<typeof selectPlayerToShowStats> = yield select(
      selectPlayerToShowStats
    );

    yield put(
      findStats({
        playername,
        pokersiteId,
      })
    );
  }
}

function* watchChangeLimitToFind() {
  while (true) {
    yield take(changeLimitFilter.type);

    const {
      playername,
      pokersiteId,
    }: ReturnType<typeof selectPlayerToShowStats> = yield select(
      selectPlayerToShowStats
    );

    yield put(
      findStats({
        playername,
        pokersiteId,
      })
    );
  }
}

function* watchTableSizeToFind() {
  while (true) {
    yield take(changeTableSizeFilter.type);

    const {
      playername,
      pokersiteId,
    }: ReturnType<typeof selectPlayerToShowStats> = yield select(
      selectPlayerToShowStats
    );

    yield put(
      findStats({
        playername,
        pokersiteId,
      })
    );
  }
}

export function generateFilters(
  tableSizeFilterValue?: number, //id фильтра
  limitFilterValue?: number, //id фильтра
  dateFilterValue?: number, //id фильтра
  customDateFilterValue?: { fromDate: string; toDate: string }
) {
  let fromDateFilter: string = undefined!;
  let toDateFilter: string = undefined!;
  let fromLimitFilter: number = undefined!;
  let toLimitFilter: number = undefined!;
  let fromTableSizeFilter: number = undefined!;
  let toTableSizeFilter: number = undefined!;

  const tableSizeOption = TableSizeOptions.find(
    x => x.id === tableSizeFilterValue
  );

  switch (tableSizeOption) {
    case undefined:
      break;
    default:
      fromTableSizeFilter = tableSizeOption.tableSize;
      toTableSizeFilter = tableSizeOption.tableSize;
  }

  const limitOption = LimitOptions.find(x => x.id === limitFilterValue);

  switch (limitOption) {
    case undefined:
      break;
    default:
      fromLimitFilter = limitOption.limit;
      toLimitFilter = limitOption.limit;
  }

  const dateOption = DateOptions.find(x => x.id === dateFilterValue);

  switch (dateOption) {
    case undefined:
      break;
    default:
      fromDateFilter = dateOption.fromDate;
      toDateFilter = dateOption.toDate;
  }

  if (customDateFilterValue) {
    fromDateFilter = customDateFilterValue.fromDate;
    toDateFilter = customDateFilterValue.toDate;
  }

  return {
    fromDateFilter,
    toDateFilter,
    fromLimitFilter,
    toLimitFilter,
    fromTableSizeFilter,
    toTableSizeFilter,
  };
}

export default function* statSaga() {
  yield fork(watchFindStat);
  yield fork(watchChangePlayernameToFind);
  yield fork(watchGetPlayersToChoose);
  yield fork(watchChangeDateToFind);
  yield fork(watchChangeLimitToFind);
  yield fork(watchTableSizeToFind);
}
