import { createAction, nanoid } from "@reduxjs/toolkit";
import { ApiRequest, ApiRequestActionPayload, httpMethods } from "../types";

type Lifecycle = {
  RESOLVED: string;
  REJECTED: string;
  PENDING: string;
};

function apiType(
  applicationName: string,
  controller: string,
  method: httpMethods
): string {
  return `${applicationName}_${controller.toUpperCase()}_${method.toUpperCase()}`;
}

export const ApiActions = {
  resolve: (lifecycle: Lifecycle, result: unknown) =>
    createAction<unknown>(lifecycle.RESOLVED)(result),
  reject: (lifecycle: Lifecycle, error: unknown) =>
    createAction<unknown>(lifecycle.REJECTED)(error),
  pending: (lifecycle: Lifecycle) => createAction(lifecycle.PENDING),
  apiAction: (
    applicationName: string,
    controller: string,
    requestMethod: httpMethods,
    server: string,
    payload: ApiRequest,
    requestType: "HTTP"
  ) => {
    const id = nanoid();
    const type = `${apiType(applicationName, controller, requestMethod)}_${id}`;
    return createAction<ApiRequestActionPayload>(type)({
      ...payload,
      requestType,
      server,
      controller,
      requestMethod,
      api: true,
    });
  },
};

export function requestLifecycleFromType(type: string): Lifecycle {
  return {
    PENDING: `${type}_PENDING`,
    RESOLVED: `${type}_RESOLVED`,
    REJECTED: `${type}_REJECTED`,
  };
}
