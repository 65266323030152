import React from "react";
import "./BasicStats.css";

import { useSelector } from "react-redux";
import { selectGeneralStats } from "../../selectors";

import Text from "../../../../components/Text/Text";
import Flex from "../../../../components/Flex/Flex";
import formatHands from "../../../../utils/formatHands";
import formatWinnings from "../../../../utils/formatWinnings";

const BasicStats = React.memo(() => {
  const generalStats = useSelector(selectGeneralStats);
  return (
    <Flex
      className="BasicStats"
      gap={32}
      align="center"
    >
      <Flex
        className="BasicStats__Hands"
        vertical
        align="center"
        gap={0}
      >
        <Text
          size={5}
          bold
        >
          {formatHands(generalStats.hands)}
        </Text>
        <Text
          size={3}
          type="secondary"
        >
          Hands
        </Text>
      </Flex>
      <Flex
        showDivider
        gap={24}
      >
        <Flex
          gap={8}
          align="center"
        >
          <Text bold>Winrate:</Text>
          <Text
            bold
            type={generalStats.winnings > 0 ? "success" : "error"}
          >
            {generalStats.bb100}
          </Text>
          <Text type="tertiary">bb100</Text>
        </Flex>

        <Flex
          gap={8}
          align="center"
        >
          <Text bold>Total winnings:</Text>
          <Text
            bold
            type={generalStats.winnings > 0 ? "success" : "error"}
          >
            {formatWinnings(generalStats.winnings, 0, true)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default BasicStats;
