import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";

const selectStat = (state: RootState) => state.stat;

const selectPlayerToShowStats = createDraftSafeSelector(
  selectStat,
  state => state.playerToShowStats
);

const selectGraphResults = createDraftSafeSelector(
  selectStat,
  state => state.graphResults
);
const selectNicknameToFind = createDraftSafeSelector(
  selectStat,
  state => state.nicknameToFind
);
const selectLimitFilterValue = createDraftSafeSelector(
  selectStat,
  state => state.limitFilterValue
);
const selectTableSizeFilterValue = createDraftSafeSelector(
  selectStat,
  state => state.tableSizeFilterValue
);
const selectDateFilterValue = createDraftSafeSelector(
  selectStat,
  state => state.dateFilterValue
);

const selectCustomDateFilterValue = createDraftSafeSelector(
  selectStat,
  state => state.customDateFilterValue
);

const selectAutocomletePlayers = createDraftSafeSelector(
  selectStat,
  state => state.autocompletePlayers
);

const selectNeedToShowChooseRoomPage = createDraftSafeSelector(
  selectStat,
  state => state.needToShowChooseRoomPage
);
const selectPlayersToChooseRoom = createDraftSafeSelector(
  selectStat,
  state => state.playersToChooseRoom
);

const selectGraphSpinner = createDraftSafeSelector(
  selectStat,
  state => state.graphSpinner
);

const selectGeneralStats = createDraftSafeSelector(
  selectStat,
  state => state.generalStats
);

const selectLimitStats = createDraftSafeSelector(
  selectStat,
  state => state.limitStats
);

const selectTableSizeStats = createDraftSafeSelector(
  selectStat,
  state => state.tableSizeStats
);

const selectMonthStats = createDraftSafeSelector(
  selectStat,
  state => state.monthStats
);

export {
  selectStat,
  selectPlayerToShowStats,
  selectGraphResults,
  selectNicknameToFind,
  selectLimitFilterValue,
  selectTableSizeFilterValue,
  selectDateFilterValue,
  selectCustomDateFilterValue,
  selectAutocomletePlayers,
  selectNeedToShowChooseRoomPage,
  selectPlayersToChooseRoom,
  selectGraphSpinner,
  selectGeneralStats,
  selectLimitStats,
  selectTableSizeStats,
  selectMonthStats,
};
