import * as React from "react";
import "./Chart.css";
import { useSelector } from "react-redux";
import { selectGraphResults, selectGraphSpinner } from "../../selectors";
import Flex from "../../../../components/Flex/Flex";
import Loader from "../../../../components/Loader/Loader";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import formatWinnings from "../../../../utils/formatWinnings";

const Chart = React.memo(() => {
  const graphResults = useSelector(selectGraphResults);
  const loading = useSelector(selectGraphSpinner);

  if (loading) {
    return (
      <Flex
        className="Chart__Loading"
        justify="center"
        align="center"
      >
        <Loader />
      </Flex>
    );
  }

  if (!graphResults) return null;

  const { result } = graphResults;

  const data = result.reduce<Array<{ result: number }>>(
    (acc, res) => {
      acc.push({
        result: +(acc[acc.length - 1].result + res / 100).toFixed(1),
      });
      return acc;
    },
    [{ result: 0 }]
  );

  return (
    <div className="Chart">
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <LineChart data={data}>
          <CartesianGrid
            vertical={false}
            opacity={0.6}
            stroke="var(--color-text-tertiary)"
            strokeDasharray="3 3"
          />
          <YAxis
            type="number"
            orientation="right"
            stroke="var(--color-text-tertiary)"
            opacity={0.6}
            strokeWidth={0.5}
            tickLine={false}
            tickCount={7}
            tickFormatter={x => formatWinnings(x, 0, true)}
            tick={{
              stroke: "var(--color-text-tertiary)",
              opacity: 1,
              fontSize: 12,
              fontWeight: 100,
              strokeWidth: 1,
            }}
          />
          <ReferenceLine
            y={0}
            stroke="#484D57"
          />
          <Line
            type="monotone"
            dataKey="result"
            stroke="var(--color-primary)"
            dot={false}
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});

export default Chart;
