import { call } from "redux-saga/effects";
import {
  SERVER_ADDRESSES,
  SERVER_APPLICATION_NAMES,
} from "../../config/serverAddresses";
import { ActionService } from "../actionService/actionService";
import { apiEndPoints } from "../../config/endPoints/api";
import { generateFilters } from "../../containers/StatPage/sagas";

const apiService = new ActionService(
  SERVER_ADDRESSES.API,
  "HTTP",
  SERVER_APPLICATION_NAMES.API
);

export default {
  user: function* () {
    return (yield call(
      [apiService, apiService.Get],
      apiEndPoints.auth.user
    )) as ReturnType<typeof apiService.Get>;
  },
  login: function* (email: string, password: string) {
    return (yield call([apiService, apiService.Post], apiEndPoints.auth.login, {
      email,
      password,
    })) as ReturnType<typeof apiService.Post>;
  },
  logout: function* () {
    return (yield call(
      [apiService, apiService.Post],
      apiEndPoints.auth.logout
    )) as ReturnType<typeof apiService.Post>;
  },
  register: function* (email: string, password: string) {
    return (yield call(
      [apiService, apiService.Post],
      apiEndPoints.auth.register,
      {
        email,
        password,
      }
    )) as ReturnType<typeof apiService.Post>;
  },
  changePassword: function* (
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    return (yield call(
      [apiService, apiService.Post],
      apiEndPoints.auth.changePassword,
      {
        email,
        oldPassword,
        newPassword,
      }
    )) as ReturnType<typeof apiService.Post>;
  },
  reset: function* (email: string) {
    return (yield call([apiService, apiService.Post], apiEndPoints.auth.reset, {
      email,
    })) as ReturnType<typeof apiService.Post>;
  },
  confirm: function* (token: string) {
    return (yield call(
      [apiService, apiService.Post],
      apiEndPoints.auth.confirm,
      {
        token,
      }
    )) as ReturnType<typeof apiService.Post>;
  },
  refresh: function* (refreshToken: string) {
    return (yield call(
      [apiService, apiService.Post],
      apiEndPoints.auth.refresh,
      {
        refreshToken,
      }
    )) as ReturnType<typeof apiService.Post>;
  },
  getPlayerGraph: function* (
    playername: string,
    pokersiteId: number,
    filters: ReturnType<typeof generateFilters>
  ) {
    return (yield call(
      [apiService, apiService.Get],
      apiEndPoints.statistics.graph,
      undefined,
      {
        playername,
        pokersiteId,
        ...filters,
      },
      false
    )) as ReturnType<typeof apiService.Get>;
  },
  getPlayerStats: function* (
    playername: string,
    pokersiteId: number,
    filters: ReturnType<typeof generateFilters>
  ) {
    return (yield call(
      [apiService, apiService.Get],
      apiEndPoints.statistics.stats,
      undefined,
      {
        playername,
        pokersiteId,
        ...filters,
      }
    )) as ReturnType<typeof apiService.Get>;
  },
  getPlayers: function* (playername: string, limit = 10) {
    return (yield call(
      [apiService, apiService.Get],
      apiEndPoints.players.players,
      undefined,
      {
        playername,
        limit,
      },
      false
    )) as ReturnType<typeof apiService.Get>;
  },
  getPlayerToChoose: function* (playername: string) {
    return (yield call(
      [apiService, apiService.Get],
      apiEndPoints.players.playerToChoose,
      undefined,
      {
        playername,
      },
      false
    )) as ReturnType<typeof apiService.Get>;
  },
};
