import {
  hideApiCallSpinner,
  initialLoadProcessed,
  setPortableMode,
  showApiCallSpinner,
  logoutSucces,
  showPage,
  showVerifyAfterSignIn,
  setVerifiedUser,
  refreshUsersTokens,
} from "./actions";
import { createSlice } from "@reduxjs/toolkit";

import User from "../../types/User";
import PagesType from "../../types/PagesType";

const initialState: {
  initialLoad: boolean;
  apiCallSpinner: boolean;
  user?: User;
  currentPage: PagesType;
  pageNumber: number;
  portableMode: boolean;
  accessToken?: string;
  refreshToken?: string;
} = {
  initialLoad: false,
  apiCallSpinner: false,
  user: undefined,
  currentPage: "HomePage",
  pageNumber: 0,
  portableMode: localStorage.getItem("portableMode") === "true",
  accessToken: localStorage.getItem("accessToken") || undefined,
  refreshToken: localStorage.getItem("refreshToken") || undefined,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(initialLoadProcessed, (state, { payload }) => {
      state.initialLoad = payload;
    });
    builder.addCase(setPortableMode, (state, { payload }) => {
      localStorage.setItem("portableMode", String(payload));
      state.portableMode = payload;
    });
    builder.addCase(showApiCallSpinner, state => {
      state.apiCallSpinner = true;
    });
    builder.addCase(hideApiCallSpinner, state => {
      state.apiCallSpinner = false;
    });

    builder.addCase(showVerifyAfterSignIn, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(setVerifiedUser, (state, { payload }) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    });
    builder.addCase(logoutSucces, state => {
      state.user = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.currentPage = "HomePage";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    });
    builder.addCase(showPage, (state, { payload }) => {
      state.currentPage = payload;
    });
    builder.addCase(refreshUsersTokens, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
    });
  },
});
