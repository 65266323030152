import React from "react";
import "./FormItem.css";
import Text from "../Text/Text";
import classNames from "classnames";

type Props = React.PropsWithChildren<{ error?: string; className?: string }>;

const FormItem = React.memo(({ children, className, error }: Props) => {
  return (
    <div className={classNames(className, "FormItem")}>
      {children}
      {error && (
        <div className="FormItem__Error">
          <Text
            type="error"
            size={1}
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  );
});

export default FormItem;
