import * as React from "react";
import "./RegisterPage.css";
import { useDispatch } from "react-redux";
import { register } from "./actions";

import Logo from "../../assets/Logo.png";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

import { Controller, useForm } from "react-hook-form";
import Text from "../../components/Text/Text";
import { useNavigate } from "react-router-dom";
import FormItem from "../../components/FormItem/FormItem";

type FormValues = {
  email: string;
  confirm: string;
  password: string;
};

const RegisterPage = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({});

  const onSubmit = handleSubmit(({ email, password }) => {
    dispatch(
      register({
        email,
        password,
      })
    );
  });

  const handleRedirectHome = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSignInClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      handleRedirectHome();
    },
    [handleRedirectHome]
  );

  return (
    <div className="RegisterPage">
      <img
        className="RegisterPage__Logo"
        src={Logo}
        onClick={handleRedirectHome}
      />
      <Text
        className="RegisterPage__Title"
        size={5}
        bold
      >
        Sign up
      </Text>
      <form
        className="RegisterPage__Form"
        onSubmit={onSubmit}
      >
        <FormItem error={errors.email?.message}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            render={({ field }) => (
              <Input
                placeholder="Email"
                autoComplete="off"
                aria-invalid={errors.email?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem error={errors.password?.message}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "required",
            }}
            render={({ field }) => (
              <Input
                placeholder="Password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.password?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem error={errors.confirm?.message}>
          <Controller
            name="confirm"
            control={control}
            rules={{
              required: "required",
              validate: value => {
                if (watch("password") !== value) {
                  return "Passwords do not match!";
                }
              },
            }}
            render={({ field }) => (
              <Input
                placeholder="Confirm password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.confirm?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem className="RegisterPage__FirstAction">
          <Button
            className="RegisterPage__Action"
            type="primary"
          >
            Sign up
          </Button>
        </FormItem>

        <FormItem>
          <Button
            className="RegisterPage__Action"
            type="default"
            onClick={handleSignInClick}
          >
            Already have an account?
          </Button>
        </FormItem>
      </form>
    </div>
  );
});

export default RegisterPage;
