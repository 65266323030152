import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import store from "./configureStore";

import CustomRouter from "./components/Router";

import App from "./containers/App";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import "./numbroConfig.ts";

export const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <CustomRouter history={history}>
      <App />
    </CustomRouter>
  </Provider>
);
