import { call, fork, take } from "redux-saga/effects";

import { confirmRegistration } from "./actions";
import { history } from "../../main";
import apiService from "../../api/apiServices/apiService";
import { toast } from "react-toastify";

function* watchConfirm() {
  while (true) {
    const { payload }: ReturnType<typeof confirmRegistration> = yield take(
      confirmRegistration.type
    );

    const { success, reject } = yield call(
      [apiService, apiService.confirm],
      payload
    );

    if (success) {
      toast.success("Congratulations, you have successfully registered.");
    }

    if (reject) {
      toast.error(reject.payload.message);
    }

    history.push(`/`);
  }
}

export default function* confirmSaga() {
  yield fork(watchConfirm);
}
