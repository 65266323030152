import { call, put, select, takeEvery } from "redux-saga/effects";

import { requestLifecycleFromType, ApiActions } from "./actionService/actions";

import {
  showApiCallSpinner,
  hideApiCallSpinner,
  refreshUsersTokens,
  logoutSucces,
} from "../containers/App/actions";

import chooseRequest from "./request/utils";
import {
  selectAccessToken,
  selectRefreshToken,
} from "../containers/App/selectors";
import apiService from "./apiServices/apiService";

function* requestAPI(action: ReturnType<typeof ApiActions.apiAction>) {
  const { payload } = action;

  const lifecycle = requestLifecycleFromType(action.type);
  yield put(ApiActions.pending(lifecycle)());

  const accessToken: ReturnType<typeof selectAccessToken> =
    yield select(selectAccessToken);
  payload.accessToken = accessToken;
  const refreshToken: ReturnType<typeof selectRefreshToken> =
    yield select(selectRefreshToken);

  if (payload.showApiCallSpinner) {
    yield put(showApiCallSpinner());
  }

  try {
    const request = chooseRequest(payload.requestType);
    const result: unknown = yield call(request, payload);
    yield put(ApiActions.resolve(lifecycle, result));
  } catch (_error) {
    const error = _error as {
      statusCode: number;
      subcode: number;
      message: string;
    };
    if (error.statusCode === 401 && error.subcode === 1) {
      if (refreshToken) {
        const { success, reject } = yield call(
          [apiService, apiService.refresh],
          refreshToken
        );

        if (success) {
          yield put(refreshUsersTokens(success.payload));
          yield put(action);
        }

        if (reject) {
          yield put(ApiActions.reject(lifecycle, error));
          yield put(logoutSucces());
        }
      } else {
        yield put(ApiActions.reject(lifecycle, error));
        yield put(logoutSucces());
      }
    } else {
      // eslint-disable-next-line no-console
      console.error("Api request failed", error);
      yield put(ApiActions.reject(lifecycle, error));
    }
  }

  if (payload.showApiCallSpinner) {
    yield put(hideApiCallSpinner());
  }
}

function* apiSaga() {
  // @ts-expect-error: saga types
  yield takeEvery((action: ReturnType<typeof ApiActions.apiAction>) => {
    return action.payload && action.payload.api;
  }, requestAPI);
}

export default apiSaga;
