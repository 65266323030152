import * as React from "react";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import HomePage from "../HomePage/HomePage";
import StatPage from "../StatPage";

import ApiCallLoader from "../../components/ApiCallLoader/ApiCallLoader";

import RegisterPage from "../RegisterPage/RegisterPage";

import {
  selectCurrentPage,
  selectApiCallSpinner,
  selectInitialLoad,
  selectPortableMode,
  selectUser,
} from "./selectors";

import { checkPortableMode } from "./utils";

import ResetPass from "../ResetPass/ResetPass";
import ConfirmPage from "../ConfirmPage";
import Header from "../../components/Header/Header";
import UserProfilePage from "../UserProfilePage/UserProfilePage";
import { ToastContainer, Zoom } from "react-toastify";

const App = React.memo(() => {
  const currentPage = useSelector(selectCurrentPage);
  const apiCallSpinner = useSelector(selectApiCallSpinner);
  const initialLoad = useSelector(selectInitialLoad);
  const portableMode = useSelector(selectPortableMode);

  const user = useSelector(selectUser);

  return (
    <section className="App">
      <ToastContainer
        autoClose={1500}
        position="top-center"
        transition={Zoom}
        hideProgressBar
        closeButton={false}
      />
      {!checkPortableMode(currentPage, portableMode) && apiCallSpinner && (
        <ApiCallLoader />
      )}

      {user && !portableMode && <Header user={user} />}
      <main className="App__Main">
        {initialLoad ? (
          <Routes>
            <Route
              path="/"
              element={<HomePage />}
            />
            <Route
              path="/register"
              element={<RegisterPage />}
            />
            <Route
              path="/statistics"
              element={<StatPage />}
            />
            <Route
              path="/reset"
              element={<ResetPass />}
            />
            <Route
              path="/confirm"
              element={<ConfirmPage />}
            />
            <Route
              path="/profile"
              element={<UserProfilePage />}
            />
          </Routes>
        ) : null}
      </main>
    </section>
  );
});

export default App;
