import * as qs from "qs";

import { ApiRequestActionPayload } from "../types";

function makePathFromParams(
  map: Map<string, unknown>,
  separator: string
): string {
  if (!(map instanceof Map)) {
    throw new Error("Invalid parameter object. Must be Map");
  }

  let mapString = "";

  map.forEach((value, key) => {
    let strToAdd = "";

    if (value instanceof Map) {
      if (typeof key === "object") {
        throw new Error(`Invalid parameter key. Must be plain type ${key}`);
      }

      strToAdd += separator + key;
      strToAdd += makePathFromParams(value, separator);
    } else {
      if (typeof value === "object") {
        throw new Error(`Invalid parameter value. Must be plain type ${key}`);
      }

      strToAdd += separator + value;
    }

    mapString += strToAdd;
  });

  return mapString;
}

async function makeRequest(server: string, url: string, options: RequestInit) {
  options.credentials = "include";
  options.mode = "cors";

  const urlToSend = `${server}/${url}`;

  // eslint-disable-next-line no-console
  console.warn("HTTP API requested | ", urlToSend, options);

  const response = await fetch(urlToSend, options);
  const responseString = await response.text();
  const body = responseString === "" ? {} : JSON.parse(responseString);
  if (response.status >= 200 && response.status < 300) {
    return body;
  }
  throw {
    statusCode: body.statusCode,
    message: body.message,
    subcode: body.subcode,
  };
}

export default function request({
  server,
  controller,
  requestMethod,
  params,
  query,
  body,
  accessToken,
}: ApiRequestActionPayload): Promise<unknown> {
  let urlString = controller;
  if (params !== undefined && params) {
    urlString += makePathFromParams(params, "/");
  }

  const queryStr = qs.stringify(query || {});
  urlString += `?${queryStr}`;

  const options: RequestInit = {
    method: requestMethod || "GET",
  };

  const headers: HeadersInit = {
    Accept: "application/json",
    "content-type": "application/json",
  };

  if (headers["content-type"] === "application/json") {
    if (body) {
      options.body = JSON.stringify(body);
    }
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  options.headers = headers;

  return makeRequest(server, urlString, options);
}
