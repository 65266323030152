import dayjs from "dayjs";

import { FilterOption } from "../../components/Filter/Filter";

export const TableSizeOptions: Array<FilterOption & { tableSize: number }> = [
  { id: 1, label: "FR", tableSize: 9 },
  { id: 2, label: "SH", tableSize: 6 },
  { id: 3, label: "HU", tableSize: 2 },
];

export const LimitOptions: Array<FilterOption & { limit: number }> = [
  { id: 1, label: "10", limit: 10 },
  { id: 2, label: "25", limit: 25 },
  { id: 3, label: "50", limit: 50 },
  { id: 4, label: "100", limit: 100 },
  { id: 5, label: "200", limit: 200 },
];

const now = dayjs();

export const DateOptions: Array<
  FilterOption & { fromDate: string; toDate: string }
> = [
  {
    id: 1,
    label: "This",
    fromDate: now.startOf("month").format("YYYY-MM-DD"),
    toDate: now.endOf("month").format("YYYY-MM-DD"),
  },
  {
    id: 2,
    label: "3M",
    fromDate: now.subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
    toDate: now.endOf("month").format("YYYY-MM-DD"),
  },
  {
    id: 3,
    label: "6M",
    fromDate: now.subtract(5, "month").startOf("month").format("YYYY-MM-DD"),
    toDate: now.endOf("month").format("YYYY-MM-DD"),
  },
  {
    id: 4,
    label: "12M",
    fromDate: now.subtract(11, "month").startOf("month").format("YYYY-MM-DD"),
    toDate: now.endOf("month").format("YYYY-MM-DD"),
  },
];
