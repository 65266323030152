const authController = "auth";
const playersController = "players";
const statisticsController = "statistics";

export const apiEndPoints = {
  auth: {
    user: `${authController}/user`,
    login: `${authController}/login`,
    logout: `${authController}/logout`,
    register: `${authController}/register`,
    confirm: `${authController}/confirm`,
    refresh: `${authController}/refresh`,
    changePassword: `${authController}/changePassword`,
    reset: `${authController}/reset`,
  },
  players: {
    players: playersController,
    playerToChoose: `${playersController}/playerToChoose`,
  },
  statistics: {
    stats: `${statisticsController}/stats`,
    graph: `${statisticsController}/graphResults`,
  },
};
