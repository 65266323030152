import React from "react";
import "./Card.css";
import Text from "../Text/Text";
import CaretDownIcon from "../../assets/Icons/CaretDownIcon.svg?react";
import Flex from "../Flex/Flex";
import classNames from "classnames";

type Props = React.PropsWithChildren<{ title: string }>;

const Card = React.memo(({ children, title }: Props) => {
  const [isHidden, setIsHidden] = React.useState(false);
  const handleHide = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className="Card">
      <Flex
        className="Card__head"
        justify="space-between"
        align="center"
      >
        <Text
          bold
          size={1}
        >
          {title}
        </Text>
        <i
          className={classNames("Card__toggler", {
            "Card__toggler--hidden ": isHidden,
          })}
          onClick={handleHide}
        >
          <CaretDownIcon />
        </i>
      </Flex>
      <div
        className={classNames("Card__body", { "Card__body--hidden": isHidden })}
      >
        {children}
      </div>
    </div>
  );
});

export default Card;
