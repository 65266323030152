import * as React from "react";
import "./ZoomApp.css";
import PlusIcon from "../../assets/Icons/PlusIcon.svg?react";
import MinusIcon from "../../assets/Icons/MinusIcon.svg?react";

const lsZoomKey = "zoom-value";

const ZoomApp = React.memo(() => {
  const lsPrevValue = localStorage.getItem(lsZoomKey);
  const zoomValueRef = React.useRef(lsPrevValue ? +lsPrevValue : 100);

  const setZoomValue = () => {
    localStorage.setItem(lsZoomKey, zoomValueRef.current.toString());
    document.body.style.zoom = `${zoomValueRef.current}%`;
    document.body.style.overflow = "hidden";
  };

  React.useEffect(() => {
    setZoomValue();

    return () => {
      document.body.style.zoom = "100%";
      document.body.style.overflow = "unset";
    };
  }, []);

  const handlePlusClick = () => {
    zoomValueRef.current += 25;
    setZoomValue();
  };

  const handleMinusClick = () => {
    if (zoomValueRef.current !== 25) {
      zoomValueRef.current -= 25;
    }
    setZoomValue();
  };

  return (
    <div className="ZoomApp">
      <div className="ZoomApp--Panel">
        <i className="ZoomApp--PanelIcon">
          <PlusIcon onClick={handlePlusClick} />
        </i>
        <i className="ZoomApp--PanelIcon">
          <MinusIcon onClick={handleMinusClick} />
        </i>
      </div>
    </div>
  );
});

export default ZoomApp;
