import React from "react";
import classNames from "classnames";
import "./Flex.css";

import { v4 as uuidv4 } from "uuid";

type Props = React.PropsWithChildren<{
  className?: string;
  vertical?: boolean;
  align?: "flex-start" | "center" | "flex-end";
  justify?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-around"
    | "space-between";
  gap?: number;
  showDivider?: boolean;
  flex?: boolean;
  wrap?: boolean;
}>;

const Flex = React.memo(
  ({
    className,
    children,
    vertical,
    align,
    justify,
    showDivider,
    gap = 8,
    flex,
    wrap,
  }: Props) => {
    return (
      <div
        style={{ gap }}
        className={classNames("Flex", className, {
          "Flex--vertical": vertical,

          "Flex--align-flex-start": align === "flex-start",
          "Flex--align-center": align === "center",
          "Flex--align-flex-end": align === "flex-end",

          "Flex--justify-flex-start": justify === "flex-start",
          "Flex--justify-center": justify === "center",
          "Flex--justify-flex-end": justify === "flex-end",
          "Flex--justify-space-around": justify === "space-around",
          "Flex--justify-space-between": justify === "space-between",

          "Flex--flex": flex,

          "Flex--flex-wrap": wrap,
        })}
      >
        {children && Array.isArray(children)
          ? children.reduce((acc, x, index) => {
              acc.push(x);
              if (index + 1 !== children.length && showDivider) {
                const uuid = uuidv4();
                if (vertical) {
                  acc.push(
                    <div
                      key={uuid}
                      className="Flex--vertical-divider"
                    />
                  );
                } else {
                  acc.push(
                    <div
                      key={uuid}
                      className="Flex--horizontal-divider"
                    />
                  );
                }
              }
              return acc;
            }, [])
          : children}
      </div>
    );
  }
);

export default Flex;
