import * as React from "react";
import "./PortableHeader.css";
import Autocomplete from "../../../../components/Autocomplete/Autocomplete";
import { useDispatch } from "react-redux";

import ZoomApp from "../../../../components/ZoomApp/ZoomApp";

import Logo from "../../../../assets/Logo.png";
import useSearchPlayers from "../../../../hooks/useSearchPlayers";
import FullscreenIcon from "../../../../assets/Icons/FullscreenIcon.svg?react";
import { setPortableMode } from "../../../App/actions";
import Flex from "../../../../components/Flex/Flex";

const PortableHeader = React.memo(() => {
  const dispatch = useDispatch();

  const {
    nicknameToFind,
    changeNicknameToFind,
    selectPlayer,
    suggestions,
    clearAutocompletePlayers,
  } = useSearchPlayers();

  const handleClosePortableMode = React.useCallback(() => {
    dispatch(setPortableMode(false));
  }, [dispatch]);

  return (
    <Flex
      className="PortableHeader"
      gap={16}
      justify="space-between"
      align="center"
    >
      <img
        className="PortableHeader__Logo"
        src={Logo}
      />
      <ZoomApp />
      <Autocomplete
        className="PortableHeader__Autocomplete"
        inputClassName="PortableHeader__Autocomplete__Input"
        placeholder="Search for players"
        inputValue={nicknameToFind}
        onChange={changeNicknameToFind}
        onSelect={selectPlayer}
        suggestions={suggestions}
        onCloseSuggests={clearAutocompletePlayers}
      />
      <i
        className="PortableHeader__FullscreenIcon"
        onClick={handleClosePortableMode}
      >
        <FullscreenIcon />
      </i>
    </Flex>
  );
});

export default PortableHeader;
