import React from "react";
import "./PortableBasicStats.css";

import { useSelector } from "react-redux";
import { selectGeneralStats, selectPlayerToShowStats } from "../../selectors";

import Text from "../../../../components/Text/Text";
import Flex from "../../../../components/Flex/Flex";
import formatHands from "../../../../utils/formatHands";

const PortableBasicStats = React.memo(() => {
  const playerToShowStats = useSelector(selectPlayerToShowStats);
  const generalStats = useSelector(selectGeneralStats);
  return (
    <Flex
      className="PortableBasicStats"
      align="center"
      justify="space-between"
    >
      <Flex vertical>
        <Text
          size={4}
          bold
        >
          {playerToShowStats.playername}
        </Text>
      </Flex>
      <Flex gap={24}>
        <Flex
          className="PortableBasicStats__Hands"
          vertical
          align="center"
          gap={0}
        >
          <Text
            size={3}
            bold
          >
            {formatHands(generalStats.hands)}
          </Text>
          <Text type="secondary">Hands</Text>
        </Flex>
        <Flex vertical>
          <Text bold>Winnings:</Text>
          <Text
            size={3}
            bold
            type={generalStats.winnings > 0 ? "success" : "error"}
          >
            {generalStats.winnings}$
          </Text>
        </Flex>
        <Flex vertical>
          <Text bold>Winrate:</Text>
          <Text
            size={3}
            bold
            type={generalStats.winnings > 0 ? "success" : "error"}
          >
            {generalStats.bb100}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default PortableBasicStats;
