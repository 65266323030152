import * as React from "react";
import "./HomePage.css";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { signIn } from "../App/actions";

import Logo from "../../assets/Logo.png";

import { Controller, useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import Text from "../../components/Text/Text";
import FormItem from "../../components/FormItem/FormItem";

type FormValues = {
  email: string;
  password: string;
};

const HomePage = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({});

  const onSubmit = handleSubmit(({ email, password }) => {
    dispatch(
      signIn({
        email,
        password,
      })
    );
  });

  const handleSignUpClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      navigate("/register");
    },
    [navigate]
  );

  return (
    <div className="HomePage">
      <img
        className="HomePage__Logo"
        src={Logo}
      />
      <Text
        className="HomePage__Title"
        size={5}
        bold
      >
        Sign in
      </Text>
      <form
        className="HomePage__Form"
        onSubmit={onSubmit}
      >
        <FormItem error={errors.email?.message}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            render={({ field }) => (
              <Input
                placeholder="Email"
                autoComplete="off"
                aria-invalid={errors.email?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem error={errors.password?.message}>
          <Controller
            name="password"
            control={control}
            rules={{ required: "required" }}
            render={({ field }) => (
              <Input
                placeholder="Password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.password?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem className="HomePage__ForgotPassword">
          <Link to="/reset">Forgot your password?</Link>
        </FormItem>

        <FormItem>
          <Button
            className="HomePage__Action"
            type="primary"
          >
            Sign in
          </Button>
        </FormItem>
        <FormItem>
          <Button
            className="HomePage__Action"
            onClick={handleSignUpClick}
          >
            Sign up
          </Button>
        </FormItem>
      </form>
    </div>
  );
});

export default HomePage;
