import * as React from "react";
import "./UserProfilePage.css";
import { useDispatch } from "react-redux";
import { changePassword } from "./actions";
import Input from "../../components/Input/Input";
import { useAuth } from "../../hooks/useAuth";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import Logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import FormItem from "../../components/FormItem/FormItem";

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const UserProfilePage = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({});

  const onSubmit = handleSubmit(
    ({ oldPassword, newPassword, confirmNewPassword }) => {
      dispatch(
        changePassword({ oldPassword, newPassword, confirmNewPassword })
      );
    }
  );

  if (!user) {
    return null;
  }

  return (
    <div className="UserProfilePage">
      <img
        className="UserProfilePage__Logo"
        src={Logo}
        onClick={() => navigate("/statistics")}
      />
      <Text
        className="UserProfilePage__Title"
        size={6}
        bold
      >
        Reset password
      </Text>
      <Text
        className="UserProfilePage__Email"
        size={3}
      >
        {user.email}
      </Text>
      <form
        className="UserProfilePage__Form"
        onSubmit={onSubmit}
      >
        <FormItem error={errors.oldPassword?.message}>
          <Controller
            name="oldPassword"
            control={control}
            rules={{
              required: "Please input your password!",
            }}
            render={({ field }) => (
              <Input
                placeholder="Old password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.oldPassword?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem error={errors.newPassword?.message}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: "required",
            }}
            render={({ field }) => (
              <Input
                placeholder="New password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.newPassword?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem error={errors.confirmNewPassword?.message}>
          <Controller
            name="confirmNewPassword"
            control={control}
            rules={{
              required: "required",
              validate: value => {
                if (watch("newPassword") !== value) {
                  return "Passwords do not match!";
                }
              },
            }}
            render={({ field }) => (
              <Input
                placeholder="Confirm password"
                autoComplete="off"
                type="password"
                aria-invalid={errors.confirmNewPassword?.message ? true : false}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem className="UserProfilePage__FirstAction">
          <Button
            className="UserProfilePage__Action"
            type="primary"
          >
            Change password
          </Button>
        </FormItem>
      </form>
    </div>
  );
});

export default UserProfilePage;
