import React from "react";
import "./PostflopStats.css";
import { useSelector } from "react-redux";
import { selectGeneralStats } from "../../selectors";
import Card from "../../../../components/Card/Card";

import Text from "../../../../components/Text/Text";
import Flex from "../../../../components/Flex/Flex";

export const PostflopStatsList = React.memo(
  ({
    name,
    values,
  }: {
    name: string;
    values: Array<number | null | string>;
  }) => {
    return (
      <Flex
        vertical
        gap={8}
        align="center"
      >
        <Text
          type="primary"
          bold
        >
          {name}
        </Text>
        {values.map((x, index) => (
          <Text
            key={index}
            bold
          >
            {x === null ? "-" : x}
          </Text>
        ))}
      </Flex>
    );
  }
);

const PostflopStats = React.memo(() => {
  const generalStats = useSelector(selectGeneralStats);
  return (
    <Card title="Postflop">
      <Flex
        className="Postflop__content"
        gap={16}
        showDivider
      >
        <Flex gap={16}>
          <Flex
            vertical
            gap={8}
            justify="flex-end"
          >
            <Text
              type="primary"
              bold
            >
              FLP
            </Text>
            <Text
              type="primary"
              bold
            >
              TRN
            </Text>
            <Text
              type="primary"
              bold
            >
              RVR
            </Text>
          </Flex>
          <PostflopStatsList
            name="Bet"
            values={[
              generalStats.flopbet,
              generalStats.turnbet,
              generalStats.riverbet,
            ]}
          />
          <PostflopStatsList
            name="FvBet"
            values={[
              generalStats.flopfold,
              generalStats.turnfold,
              generalStats.riverfold,
            ]}
          />
          <PostflopStatsList
            name="RBet"
            values={[
              generalStats.flopraise,
              generalStats.turnraise,
              generalStats.riverraise,
            ]}
          />
        </Flex>

        <Flex gap={16}>
          <PostflopStatsList
            name="CBet"
            values={[
              generalStats.cbetflop,
              generalStats.cbetturn,
              generalStats.cbetriver,
            ]}
          />
          <PostflopStatsList
            name="FvCb"
            values={[
              generalStats.foldflopcbet,
              generalStats.foldturncbet,
              generalStats.foldrivercbet,
            ]}
          />
          <PostflopStatsList
            name="RCb"
            values={[
              generalStats.raisedflopcontinuationbet,
              generalStats.raisedturncontinuationbet,
              generalStats.raisedrivercontinuationbet,
            ]}
          />
        </Flex>

        <PostflopStatsList
          name="Agf %"
          values={[
            generalStats.flopagrfactor,
            generalStats.turnagrfactor,
            generalStats.riveragrfactor,
          ]}
        />
      </Flex>
    </Card>
  );
});

export default PostflopStats;
