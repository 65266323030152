import { fork, take, call } from "redux-saga/effects";

import { register } from "./actions";
import { history } from "../../main";
import apiService from "../../api/apiServices/apiService";
import { toast } from "react-toastify";

function* watchRegister() {
  while (true) {
    const { payload }: ReturnType<typeof register> = yield take(register.type);

    const { email, password } = payload;

    const { success, reject } = yield call(
      [apiService, apiService.register],
      email,
      password
    );

    if (success) {
      toast.success(
        `Registration was completed successfully. Please check the email "spam"`
      );
      history.push("/");
    }

    if (reject) {
      toast.error(reject.payload.message);
    }
  }
}

export default function* registerSaga() {
  yield fork(watchRegister);
}
