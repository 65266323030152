import * as React from "react";
import "./PortableLayout.css";
import { useSelector } from "react-redux";
import PortableHeader from "../PortableHeader/PortableHeader";
import PortableStats from "../PortableStats/PortableStats";
import { selectApiCallSpinner } from "../../../App/selectors";
import PortableLoader from "../../../../components/PortableLoader/PortableLoader";

const PortableLayout = React.memo(() => {
  const apiCallSpinner = useSelector(selectApiCallSpinner);
  return (
    <section className="PortableLayout">
      {apiCallSpinner && <PortableLoader />}
      <PortableHeader />
      <PortableStats />
      {/* {needToShowRoom ? <PortableChooseRoomPage /> : <PortableStats />} */}
    </section>
  );
});

export default PortableLayout;
