import * as React from "react";
import "./ApiCallLoader.css";

import Loader from "../Loader/Loader";
import Flex from "../Flex/Flex";

const ApiCallLoader = React.memo(() => {
  const [hidden, setHidden] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => setHidden(false), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <Flex
      className="ApiCallLoader"
      align="center"
      justify="center"
    >
      <Loader />
    </Flex>
  );
});

export default ApiCallLoader;
