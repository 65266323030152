import React from "react";
import "./Header.css";

import Logo from "../../assets/Logo.png";

import FullscreenExitIcon from "../../assets/Icons/FullscreenExitIcon.svg?react";
import LogoutIcon from "../../assets/Icons/LogoutIcon.svg?react";

import User from "../../types/User";
import Text from "../Text/Text";
import Autocomplete from "../Autocomplete/Autocomplete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout, setPortableMode } from "../../containers/App/actions";
import useSearchPlayers from "../../hooks/useSearchPlayers";

type Props = {
  user: User;
};

const Header = React.memo(({ user }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    nicknameToFind,
    changeNicknameToFind,
    suggestions,
    clearAutocompletePlayers,
    selectPlayer,
  } = useSearchPlayers();

  const handlelogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleOpenPortableMode = React.useCallback(() => {
    dispatch(setPortableMode(true));
  }, [dispatch]);

  const handleRedirectUserProfile = () => {
    navigate("/profile");
  };

  return (
    <header className="Header">
      <div className="Header__Content">
        <div className="Header__Left">
          <img
            className="Header__Logo"
            src={Logo}
            onClick={() => navigate("/statistics")}
          />

          <Autocomplete
            className="Header__Autocomplete"
            inputClassName="Header__Autocomplete__Input"
            placeholder="Search for players"
            inputValue={nicknameToFind}
            onChange={changeNicknameToFind}
            onSelect={selectPlayer}
            suggestions={suggestions}
            onCloseSuggests={clearAutocompletePlayers}
          />
          <i
            className="Header__FullscreenExitIcon"
            onClick={handleOpenPortableMode}
          >
            <FullscreenExitIcon />
          </i>
        </div>
        <div className="Header__Right">
          <div
            onClick={handleRedirectUserProfile}
            className="Header__Email-wrapper"
          >
            <Text
              size={3}
              className="Header__Email-content"
            >
              {user.email}
            </Text>
          </div>
          <i
            className="Header__LogoutIcon"
            onClick={handlelogout}
          >
            <LogoutIcon />
          </i>
        </div>
      </div>
    </header>
  );
});

export default Header;
