import numbro from "numbro";

export default function formatWinnings(
  num: number,
  mantissa: number,
  average: boolean
) {
  const format: numbro.Format = {
    mantissa,
    trimMantissa: true,
    thousandSeparated: true,
    average,
    spaceSeparated: false,
  };

  if (num >= 1e3 && average) {
    let forceAvg: numbro.Format["forceAverage"];
    switch (true) {
      case num < 1e6:
        forceAvg = "thousand";
        break;
      case num < 1e9:
        forceAvg = "million";
        break;
      case num < 1e12:
        forceAvg = "billion";
        break;
      default:
        forceAvg = "trillion";
    }
    format.forceAverage = forceAvg;
  }
  return numbro(num).formatCurrency(format);
}
