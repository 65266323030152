import React from "react";
import "./Filter.css";

import Flex from "../Flex/Flex";
import Text from "../Text/Text";
import classNames from "classnames";

export type FilterOption = {
  id: number;
  label: string;
};

type Props = {
  label: string;
  options: Array<FilterOption>;
  value?: number;
  onChange: (id?: number) => void;
  additionalContent?: React.ReactNode;
  allSelectedFunction?: () => boolean;
};

const Filter = React.memo(
  ({
    label,
    options,
    value,
    onChange,
    additionalContent,
    allSelectedFunction,
  }: Props) => {
    return (
      <Flex
        vertical
        gap={4}
      >
        <Text type="secondary">{label}</Text>
        <Flex
          className="Filter"
          align="center"
        >
          <Flex gap={4}>
            {options.map(x => (
              <div
                key={x.id}
                className={classNames("Filter__Option", {
                  "Filter__Option--active": value === x.id,
                })}
                onClick={() => {
                  if (value !== x.id) {
                    onChange(x.id);
                  }
                }}
              >
                <Text
                  size={1}
                  bold
                >
                  {x.label}
                </Text>
              </div>
            ))}
            <div
              className={classNames("Filter__Option", {
                "Filter__Option--active": allSelectedFunction
                  ? allSelectedFunction()
                  : value === undefined,
              })}
              onClick={() => {
                if (value !== undefined) {
                  onChange();
                }
              }}
            >
              <Text
                size={1}
                bold
              >
                All
              </Text>
            </div>
          </Flex>
          {additionalContent}
        </Flex>
      </Flex>
    );
  }
);

export default Filter;
