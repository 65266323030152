import { fork, take, put, call } from "redux-saga/effects";

import { setVerifiedUser, signIn } from "../App/actions";
import LoginDto from "../../dto/LoginDto";
import apiService from "../../api/apiServices/apiService";
import { history } from "../../main";
import { toast } from "react-toastify";

function* watchSignIn() {
  while (true) {
    const { payload }: ReturnType<typeof signIn> = yield take(signIn.type);
    const { email, password } = payload;

    const { success, reject } = yield call(
      [apiService, apiService.login],
      email,
      password
    );

    if (success) {
      const { payload }: { payload: LoginDto } = success;
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
      yield put(setVerifiedUser(payload));
      history.push(`/statistics`);
    }
    if (reject) {
      toast.error(reject.payload.message);
    }
  }
}

export default function* homeSaga() {
  yield fork(watchSignIn);
}
