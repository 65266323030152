import { createSlice } from "@reduxjs/toolkit";
import {
  changeDateFilter,
  changeLimitFilter,
  changePlayernameToFind,
  changeTableSizeFilter,
  dropFilters,
  getPlayersToChoose,
  setLoadedBaseStats,
  setLoadedGraphStats,
  setLoadedPlayers,
  setLoadedPlayersToChoose,
  showGraphError,
  showLoadingGraphSpinner,
} from "./actions";
import AutocompletePlayerModel from "../../viewModel/AutocompletePlayerModel";
import { FullStatsDto, ShortStatsDto } from "../../dto/PlayerStatsDto";
import PlayerToChooseRoomModel from "../../viewModel/PlayersToChooseRoomModel";
import { pokerSitePostfix } from "./utils";

const initialState: {
  autocompletePlayers: Array<AutocompletePlayerModel>;

  playerToShowStats: {
    playername: string;
    pokersiteId: 1;
    pokersitePostfix: string;
  };

  limitStats: Array<{ limit: number } & ShortStatsDto>;
  tableSizeStats: Array<{ tableSize: number } & ShortStatsDto>;
  monthStats: Array<{ month: string } & ShortStatsDto>;
  generalStats: FullStatsDto;

  graphResults: {
    result: Array<number>;
  };
  graphSpinner: boolean;

  tableSizeFilterValue?: number;
  limitFilterValue?: number;
  dateFilterValue?: number;
  customDateFilterValue?: {
    fromDate: string;
    toDate: string;
  };

  nicknameToFind?: string;

  needToShowChooseRoomPage: boolean;
  playersToChooseRoom: Array<PlayerToChooseRoomModel>;
} = {
  autocompletePlayers: [],
  playerToShowStats: undefined!,

  limitStats: [],
  tableSizeStats: [],
  monthStats: [],
  generalStats: undefined!,

  graphResults: undefined!,
  graphSpinner: false,

  needToShowChooseRoomPage: false,
  playersToChooseRoom: [],
};

export const statReducer = createSlice({
  name: "stat",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(changePlayernameToFind, (state, { payload }) => {
      state.nicknameToFind = payload;
      state.autocompletePlayers = [];
    });
    builder.addCase(changeLimitFilter, (state, { payload }) => {
      state.limitFilterValue = payload;
    });
    builder.addCase(changeTableSizeFilter, (state, { payload }) => {
      state.tableSizeFilterValue = payload;
    });
    builder.addCase(changeDateFilter, (state, { payload }) => {
      const { value, customValue } = payload;
      state.dateFilterValue = value;
      state.customDateFilterValue = customValue;
    });
    builder.addCase(setLoadedPlayers, (state, { payload }) => {
      state.autocompletePlayers = payload;
    });
    builder.addCase(setLoadedPlayersToChoose, (state, { payload }) => {
      state.playersToChooseRoom = payload;
      state.needToShowChooseRoomPage = true;
    });
    builder.addCase(setLoadedBaseStats, (state, { payload }) => {
      const {
        playername,
        pokersiteId,
        limitStats,
        tableSizeStats,
        generalStats,
        monthStats,
      } = payload;

      state.needToShowChooseRoomPage = false;
      state.nicknameToFind = "";

      document.title = playername.toUpperCase();

      state.playerToShowStats = {
        playername,
        pokersiteId,
        pokersitePostfix: pokerSitePostfix[pokersiteId],
      };
      state.tableSizeStats = tableSizeStats.sort(
        (a, b) => b.tableSize - a.tableSize
      );
      state.limitStats = limitStats.sort((a, b) => a.limit - b.limit);
      state.monthStats = monthStats.sort((a, b) => +b.month - +a.month);
      state.generalStats = generalStats;
    });
    builder.addCase(setLoadedGraphStats, (state, { payload }) => {
      state.graphResults = payload;
      state.graphSpinner = false;
    });

    builder.addCase(showLoadingGraphSpinner, state => {
      state.graphSpinner = true;
    });
    builder.addCase(showGraphError, state => {
      state.graphSpinner = false;
    });
    builder.addCase(getPlayersToChoose, () => initialState);
    builder.addCase(dropFilters, state => {
      state.limitFilterValue = undefined;
      state.tableSizeFilterValue = undefined;
      state.dateFilterValue = undefined;
      state.customDateFilterValue = undefined;
      state.autocompletePlayers = [];
    });
  },
});
