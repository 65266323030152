import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";

const selectGlobal = (state: RootState) => state.global;

const selectInitialLoad = createDraftSafeSelector(
  selectGlobal,
  state => state.initialLoad
);
const selectApiCallSpinner = createDraftSafeSelector(
  selectGlobal,
  state => state.apiCallSpinner
);
const selectUser = createDraftSafeSelector(selectGlobal, state => state.user);

const selectCurrentPage = createDraftSafeSelector(
  selectGlobal,
  state => state.currentPage
);
const selectPortableMode = createDraftSafeSelector(
  selectGlobal,
  state => state.portableMode
);
const selectAccessToken = createDraftSafeSelector(
  selectGlobal,
  state => state.accessToken
);
const selectRefreshToken = createDraftSafeSelector(
  selectGlobal,
  state => state.refreshToken
);

export {
  selectGlobal,
  selectInitialLoad,
  selectApiCallSpinner,
  selectUser,
  selectCurrentPage,
  selectPortableMode,
  selectAccessToken,
  selectRefreshToken,
};
