import React from "react";
import "./PreflopStats.css";
import { useSelector } from "react-redux";
import { selectGeneralStats } from "../../selectors";
import Card from "../../../../components/Card/Card";
import Flex from "../../../../components/Flex/Flex";
import Text from "../../../../components/Text/Text";

export const PreflopStatsList = React.memo(
  ({
    names,
    values,
  }: {
    names: Array<string>;
    values: Array<number | null>;
  }) => {
    return (
      <Flex gap={8}>
        <Flex
          vertical
          gap={8}
        >
          {names.map((x, index) => (
            <Text
              key={index}
              type="primary"
              bold
            >
              {x}
            </Text>
          ))}
        </Flex>
        <Flex
          vertical
          gap={8}
          align="center"
        >
          {values.map((x, index) => (
            <Text
              key={index}
              bold
            >
              {x === null ? "-" : x}
            </Text>
          ))}
        </Flex>
      </Flex>
    );
  }
);

const PreflopStats = React.memo(() => {
  const generalStats = useSelector(selectGeneralStats);
  return (
    <Card title="Preflop">
      <Flex
        className="PreflopStats__content"
        gap={16}
        showDivider
        justify="space-around"
      >
        <Flex
          gap={8}
          className="PreflopStats__content-group"
        >
          <Flex
            vertical
            align="flex-end"
          >
            <Flex
              flex
              align="center"
            >
              <Text
                size={3}
                bold
                type="primary"
              >
                VPIP
              </Text>
            </Flex>
            <Flex
              flex
              align="center"
            >
              <Text
                size={3}
                bold
                type="primary"
              >
                PFR
              </Text>
            </Flex>
          </Flex>
          <Flex
            vertical
            align="center"
          >
            <Text
              size={6}
              bold
            >
              {generalStats.vpip}
            </Text>
            <Text
              size={6}
              bold
            >
              {generalStats.pfr}
            </Text>
          </Flex>
        </Flex>

        <PreflopStatsList
          names={["3bet", "F3bet", "SQZ", "CC"]}
          values={[
            generalStats.threebet,
            generalStats.foldedtothreebetpreflop,
            generalStats.squeeze,
            generalStats.coldcall,
          ]}
        />

        <PreflopStatsList
          names={["WTSD", "WWSF", "W$SD"]}
          values={[
            generalStats.sawshowdown,
            generalStats.wonwhensawflop,
            generalStats.wonshowdown,
          ]}
        />

        <PreflopStatsList
          names={["Call3bet", "4bet", "F4bet", "5bet"]}
          values={[
            generalStats.calledthreebetpreflop,
            generalStats.raisedthreebetpreflop,
            generalStats.foldedtofourbetpreflop,
            generalStats.raisedfourbetpreflop,
          ]}
        />

        <PreflopStatsList
          names={["SB resteal", "BB resteal"]}
          values={[
            generalStats.smallblindresteal,
            generalStats.bigblindresteal,
          ]}
        />
      </Flex>
    </Card>
  );
});

export default PreflopStats;
