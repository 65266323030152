import React from "react";
import "./StatsHeader.css";
import Flex from "../../../../components/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomDateFilterValue,
  selectDateFilterValue,
  selectLimitFilterValue,
  selectPlayerToShowStats,
  selectTableSizeFilterValue,
} from "../../selectors";
import Text from "../../../../components/Text/Text";
import Filter from "../../../../components/Filter/Filter";
import { DateOptions, LimitOptions, TableSizeOptions } from "../../constants";
import {
  changeDateFilter,
  changeLimitFilter,
  changeTableSizeFilter,
} from "../../actions";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

const StatsHeader = React.memo(() => {
  const dispatch = useDispatch();

  const playerToShowStats = useSelector(selectPlayerToShowStats);

  const tableSizeFilterValue = useSelector(selectTableSizeFilterValue);
  const limitFilterValue = useSelector(selectLimitFilterValue);
  const dateFilterValue = useSelector(selectDateFilterValue);
  const customDateFilterValue = useSelector(selectCustomDateFilterValue);

  React.useEffect(() => {
    if (!customDateFilterValue) {
      setDateRange([null, null]);
    }
  }, [customDateFilterValue]);

  const handleChangeTableSizeFilter = React.useCallback(
    (id?: number) => {
      dispatch(changeTableSizeFilter(id));
    },
    [dispatch]
  );

  const handleChangeLimitFilter = React.useCallback(
    (id?: number) => {
      dispatch(changeLimitFilter(id));
    },
    [dispatch]
  );

  const handleChangeDateFilter = React.useCallback(
    (id?: number) => {
      dispatch(changeDateFilter({ value: id }));
      const dateOption = DateOptions.find(x => x.id === id);
      if (dateOption) {
        setDateRange([
          new Date(dateOption.fromDate),
          new Date(dateOption.toDate),
        ]);
      } else {
        setDateRange([null, null]);
      }
    },
    [dispatch]
  );

  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  const handleChangeCustomDateFilter = React.useCallback(
    (value: [Date | null, Date | null]) => {
      setDateRange(value);
    },
    []
  );

  const handleBlurCustomDateFilter = React.useCallback(() => {
    if (startDate === null && endDate === null) {
      return;
    }

    if (endDate === null) {
      setDateRange([null, null]);
    }

    dispatch(
      changeDateFilter({
        customValue: {
          fromDate: dayjs(startDate).format("YYYY-MM-DD"),
          toDate: dayjs(endDate).format("YYYY-MM-DD"),
        },
      })
    );
  }, [dispatch, startDate, endDate]);

  return (
    <Flex
      className="StatsHeader"
      justify="space-between"
      align="center"
    >
      <Flex
        gap={24}
        align="center"
      >
        <Flex
          className="StatsHeader__Site"
          justify="center"
          align="center"
        >
          <Text
            type="secondary"
            size={3}
          >
            {playerToShowStats.pokersitePostfix}
          </Text>
        </Flex>
        <Text
          bold
          size={6}
        >
          {playerToShowStats.playername}
        </Text>
      </Flex>
      <Flex
        gap={16}
        className="StatsHeader__Filters"
      >
        <Filter
          label="Table size"
          options={TableSizeOptions}
          value={tableSizeFilterValue}
          onChange={handleChangeTableSizeFilter}
        />

        <Filter
          label="Limit"
          options={LimitOptions}
          value={limitFilterValue}
          onChange={handleChangeLimitFilter}
        />

        <Filter
          label="Date"
          options={DateOptions}
          value={dateFilterValue}
          onChange={handleChangeDateFilter}
          additionalContent={
            <div className="StatsHeader__DatePicker">
              <DatePicker
                placeholderText="Select date"
                showPopperArrow={false}
                selectsRange
                startDate={startDate!}
                endDate={endDate!}
                onChange={handleChangeCustomDateFilter}
                onCalendarClose={handleBlurCustomDateFilter}
                disabledKeyboardNavigation
                onKeyDown={e => {
                  e.preventDefault();
                }}
                excludeDateIntervals={[
                  {
                    start: new Date(),
                    end: new Date("2030-01-01"),
                  },
                ]}
              />
            </div>
          }
          allSelectedFunction={() =>
            dateFilterValue == undefined &&
            startDate === null &&
            endDate === null
          }
        />
      </Flex>
    </Flex>
  );
});

export default StatsHeader;
