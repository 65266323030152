import * as React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { confirmRegistration } from "./actions";

const ConfirmPage = React.memo(() => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  React.useEffect(() => {
    if (token) {
      dispatch(confirmRegistration(token));
    }
  }, [dispatch, token]);

  return null;
});

export default ConfirmPage;
