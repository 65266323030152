import * as React from "react";
import "./Autocomplete.css";

import Suggestions, { Suggestion } from "../Suggestions/Suggestions";
import Input from "../Input/Input";
import classNames from "classnames";

interface AutocompleteProps {
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  suggestions: Array<Suggestion>;
  onSelect: (suggestion: Suggestion) => void;
  onCloseSuggests?: () => void;
  inputValue?: string;
}

const Autocomplete = React.memo(
  ({
    className,
    inputClassName,
    placeholder,
    onChange,
    suggestions,
    onSelect,
    onCloseSuggests,
    inputValue,
  }: AutocompleteProps) => {
    const [needToShowSuggests, setNeedToShowSuggests] = React.useState(false);
    const getAbsoluteRef =
      React.useRef() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
      const handleOutsideClick = (e: MouseEvent) => {
        if (
          getAbsoluteRef.current &&
          !getAbsoluteRef.current.contains(e.target as Node)
        ) {
          if (needToShowSuggests && onCloseSuggests) {
            onCloseSuggests();
          }
          setNeedToShowSuggests(false);
        }
      };
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [onCloseSuggests, needToShowSuggests]);

    React.useEffect(() => {
      if (suggestions && suggestions.length > 0) {
        setNeedToShowSuggests(true);
      }
    }, [suggestions]);

    const onSelectSuggest = React.useCallback(
      (suggestion: Suggestion) => {
        onSelect(suggestion);
        setNeedToShowSuggests(false);
      },
      [onSelect]
    );

    return (
      <div
        className={classNames(className, "Autocomplete")}
        ref={getAbsoluteRef}
      >
        <Input
          className={inputClassName}
          placeholder={placeholder}
          onChange={onChange}
          value={inputValue}
        />
        {needToShowSuggests ? (
          <Suggestions
            suggestions={suggestions}
            onClick={onSelectSuggest}
          />
        ) : null}
      </div>
    );
  }
);

export default Autocomplete;
