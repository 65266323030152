import React from "react";
import "./Input.css";

import RcInput, { InputRef, InputProps } from "rc-input";
import classNames from "classnames";

type Props = InputProps;

const Input = React.memo(
  React.forwardRef(
    ({ className, ...rest }: Props, ref: React.Ref<InputRef>) => {
      return (
        <RcInput
          className={classNames("Input", className)}
          ref={ref}
          {...rest}
        />
      );
    }
  )
);

export default Input;
