import React from "react";
import "./SummaryStats.css";
import Card from "../../../../components/Card/Card";
import Table from "../../../../components/Table/Table";
import { useSelector } from "react-redux";
import { selectLimitStats, selectTableSizeStats } from "../../selectors";
import { ShortStatsDto } from "../../../../dto/PlayerStatsDto";
import Text from "../../../../components/Text/Text";
import exhaustiveCheck from "../../../../utils/exhaustiveCheck";
import formatHands from "../../../../utils/formatHands";
import formatWinnings from "../../../../utils/formatWinnings";

function getTextNameOfLimit(limit: 2 | 6 | 9): string {
  switch (limit) {
    case 2:
      return "HU";
    case 6:
      return "SH";
    case 9:
      return "FR";
    default:
      return exhaustiveCheck(limit);
  }
}

const SummaryStats = React.memo(() => {
  const tableSizeStats = useSelector(selectTableSizeStats);
  const limitStats = useSelector(selectLimitStats);
  return (
    <Card title="Summary">
      <Table
        columns={[
          {
            title: "",
            dataIndex: "tableSize",
            render: (
              x:
                | ({ limit: number } & ShortStatsDto)
                | ({ tableSize: 2 | 6 | 9 } & ShortStatsDto)
            ) => {
              if ("limit" in x) {
                return <Text>NL{x.limit}</Text>;
              }

              if ("tableSize" in x) {
                return <Text>{getTextNameOfLimit(x.tableSize)}</Text>;
              }

              return null;
            },
          },
          {
            title: "Hands",
            dataIndex: "hands",
            render: (x: ShortStatsDto) => (
              <Text>{formatHands(x.hands, 1)}</Text>
            ),
          },
          {
            title: "Winnings",
            dataIndex: "winnings",
            render: (x: ShortStatsDto) => (
              <Text type={x.winnings > 0 ? "success" : "error"}>
                {formatWinnings(x.winnings, 0, false)}
              </Text>
            ),
          },
          { title: "bb/100", dataIndex: "bb100" },
          { title: "VPIP", dataIndex: "vpip" },
          { title: "PFR", dataIndex: "pfr" },
          { title: "WWSF", dataIndex: "wonwhensawflop" },
        ]}
        data={tableSizeStats.concat(limitStats as any)}
        spaceIndexes={[tableSizeStats.length - 1]}
      />
    </Card>
  );
});

export default SummaryStats;
