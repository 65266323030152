import { fork, take, put, select, call } from "redux-saga/effects";
import { selectUser } from "../App/selectors";
import { logoutSucces } from "../App/actions";
import { changePassword } from "./actions";
import apiService from "../../api/apiServices/apiService";
import { history } from "../../main";
import { toast } from "react-toastify";

function* watchChangePassword() {
  while (true) {
    const { payload }: ReturnType<typeof changePassword> = yield take(
      changePassword.type
    );
    const { oldPassword, newPassword } = payload;
    const user: ReturnType<typeof selectUser> = yield select(selectUser);

    if (user) {
      const { success, reject } = yield call(
        [apiService, apiService.changePassword],
        user.email,
        oldPassword,
        newPassword
      );
      if (success) {
        toast.success("Password changing succes. Sign in now.");
        yield put(logoutSucces());
        history.push("/");
      }
      if (reject) {
        toast.error(reject.payload.message);
      }
    }
  }
}

export default function* userProfileSaga() {
  yield fork(watchChangePassword);
}
